import { CheckCircleIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import useTranslation from '../i18n/useTranslation';
import * as passwordUtil from '../utils/password';

const PasswordRulesV2 = ({ password, touched }) => {
  const { t } = useTranslation();

  return (
    <ul>
      {passwordUtil.rules(t).map((pr, index) => {
        const isValid = pr.isValid(password);
        const isDirty = touched || password.length > 0;

        return (
          <li className="mt-2 flex items-center" key={index}>
            <div
              className={cx({
                ['text-grayv2-300']: !isValid,
                ['text-black']: isValid,
              })}
            >
              <CheckCircleIcon className="h-6 w-6" />
            </div>
            <span
              className={cx('ml-3 text-left', {
                ['text-grayv2-300']: isDirty && !isValid,
                ['text-black']: !isDirty || isValid,
              })}
            >
              {pr.name}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default PasswordRulesV2;
