import { useResponsive } from '@hooks/useResponsive';
import LoginLayout from '@layouts/v2/login.layout';
import ButtonV2, {
  ButtonV2Rounded,
  ButtonV2Size,
  ButtonV2Style,
} from '@ui-kit/v2/Button';
import { InputTextDecorator } from '@ui-kit/v2/inputs/InputText';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineXCircle,
} from 'react-icons/hi';
import PasswordRulesV2 from 'src/signup/PasswordRulesV2';
import {
  login as authLogin,
  handleSamlSPSSORedirection,
} from '../app-state/auth';
import config from '../config';
import API_PATHS from '../constants/apiPaths';
import ERRORS from '../constants/error';
import { ERROR_CODES, ERROR_CODES_MAP } from '../constants/errors';
import { GOOGLE_ANALYTICS_EVENTS } from '../constants/googleAnalyticsEvent';
import NEXT_API_PATHS from '../constants/nextApiPaths';
import { SAML_LOGIN_ROUTE } from '../constants/routes';
import WEB_PATHS from '../constants/webPaths';
import { authHttp } from '../http';
import useTranslation from '../i18n/useTranslation';
import { emailSchema, passwordSchema } from '../signup/login.schema';
import { PencilSquare } from '../ui-kit/icons';
import { useLmsRouteRedirectQuery } from '../ui-kit/useLmsRouteRedirectQuery';
import { getReCaptchaToken } from '../utils/recaptcha';

interface ILoginForm {
  email?: string;
  password?: string;
}

interface ILoginFormProps {
  email: string;
  password: string;
  loginState: LoginState;
  onSubmit: (
    values: {
      email: string;
      password: string;
    },
    formikHelpers: FormikHelpers<ILoginForm>,
  ) => void | Promise<any>;
  isEmailNotFound?: boolean;
  handleGoBack?: () => void;
  error?: string;
}

const LoginForm: React.FunctionComponent<ILoginFormProps> = ({
  email,
  password,
  loginState,
  isEmailNotFound,
  onSubmit,
  handleGoBack,
  error,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { lg } = useResponsive();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const focusInput = (ref: React.MutableRefObject<HTMLInputElement>) => {
    ref.current.scrollIntoView();
    ref.current.focus();
  };
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: password || '',
    },
    validateOnBlur: false,
    validationSchema:
      loginState === LoginState.PASSWORD ? passwordSchema : emailSchema,
    onSubmit,
  });

  useEffect(() => {
    if (loginState === LoginState.EMAIL) {
      focusInput(emailRef);
    } else if (loginState === LoginState.PASSWORD) {
      focusInput(passwordRef);
    }
  }, [loginState]);

  const setTouchState = (item: ILoginForm, targetState: boolean) => {
    const touchState = {};
    Object.keys(item).forEach((k) => {
      touchState[k] = targetState;
    });

    return touchState;
  };

  const handleChange = (e) => {
    const touchState = setTouchState(formik.initialValues, false);
    formik.handleChange(e);
    formik.setTouched(touchState);
  };

  const handleBlur = (e) => {
    const touchState = setTouchState(formik.initialValues, true);
    formik.setTouched(touchState);
    formik.handleBlur(e);
  };

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="flex flex-col gap-y-6 lg:gap-y-8"
      >
        <div>
          <p className="mb-2 text-left text-p2 font-regular text-grayv2-900">
            {t('signinPage.email')}
          </p>
          {InputTextDecorator.decorate(
            {
              rightComponent: (
                <div
                  onClick={() => {
                    handleGoBack();
                    // To prevent button disabled when go back
                    formik.resetForm({ values: { email: '', password: '' } });
                    formik.setFieldValue('email', email);
                  }}
                >
                  <PencilSquare
                    className={classNames(
                      `h-5 w-5 cursor-pointer text-grayv2-700 ${
                        loginState === LoginState.EMAIL ||
                        loginState === LoginState.SSO_LOGIN
                          ? 'hidden'
                          : ''
                      }`,
                    )}
                  />{' '}
                </div>
              ),
              inputProps: {
                disabled:
                  loginState !== LoginState.EMAIL &&
                  loginState !== LoginState.SSO_LOGIN,
                placeholder: t('signinPage.email'),
                onBlur: handleBlur,
                onChange: handleChange,
                className: classNames(
                  'disabled:!bg-white',
                  formik.touched.email && formik.errors.email
                    ? 'text-redv2-900'
                    : '',
                ),
                type: 'email',
                ref: emailRef,
              },
              valid: !(formik.touched.email && formik.errors.email),
            },
            [
              InputTextDecorator.withFormikField('email'),
              InputTextDecorator.withErrorMessage(
                <div className="text-left text-footnote text-red-200">
                  {isEmailNotFound ? (
                    <Link
                      href={{
                        pathname: WEB_PATHS.SIGN_UP,
                        query: router.query,
                      }}
                    >
                      <a>{t('signinPage.errors.emailNotExists')}</a>
                    </Link>
                  ) : (
                    <p>{formik.errors.email && t(formik.errors.email)}</p>
                  )}
                </div>,
              ),
            ],
          )}
        </div>

        {loginState === LoginState.PASSWORD && (
          <>
            <div>
              <p className="mb-2 text-left text-p2 font-regular text-grayv2-900">
                {t('password')}
              </p>
              {InputTextDecorator.decorate(
                {
                  rightComponent: (
                    <div>
                      <HiOutlineEye
                        className={classNames(
                          `h-5 w-5 cursor-pointer text-gray-600 hover:text-grayv2-900 ${
                            show ? 'hidden' : ''
                          }`,
                        )}
                        onClick={() => setShow(true)}
                      />
                      <HiOutlineEyeOff
                        className={classNames(
                          `h-5 w-5 cursor-pointer text-gray-600 hover:text-grayv2-900 ${
                            show ? '' : 'hidden'
                          }`,
                        )}
                        onClick={() => setShow(false)}
                      />
                    </div>
                  ),
                  inputProps: {
                    type: show ? 'text' : 'password',
                    onBlur: handleBlur,
                    onChange: handleChange,
                    ref: passwordRef,
                    placeholder: t('password'),
                  },
                  valid: !formik.touched.password || !formik.errors.password,
                },
                [
                  InputTextDecorator.withFormikField('password'),
                  InputTextDecorator.withErrorMessage(
                    <p className="text-left text-footnote text-red-200">
                      {formik.errors.password === 'required'
                        ? t('required')
                        : t('signinPage.invalidPasswordFormat')}
                    </p>,
                  ),
                ],
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="flex flex-col gap-y-4">
                <PasswordRulesV2
                  touched={formik.touched.password}
                  password={formik.values.password}
                />
              </div>
            )}
          </>
        )}

        {error && <ErrorContainer>{error}</ErrorContainer>}
        <QueryErrors />

        <div className="flex flex-col-reverse justify-between gap-y-4 lg:flex-row">
          <div className="lg:py-2">
            {loginState === LoginState.EMAIL ||
            loginState === LoginState.SSO_LOGIN ? (
              <div className="text-center text-p1 font-regular text-grayv2-900 lg:text-left">
                <>
                  <span>{t('signinPage.dontHaveAccount')}</span>
                  <span className="ml-2">
                    <Link
                      href={{
                        pathname: WEB_PATHS.SIGN_UP,
                        query: router.query,
                      }}
                    >
                      <a
                        onClick={() =>
                          ReactGA.event({
                            category:
                              GOOGLE_ANALYTICS_EVENTS.EVENT_CATEGORY.BUTTON,
                            action:
                              GOOGLE_ANALYTICS_EVENTS.EVENT_ACTION.BUTTON.CLICK,
                            label: GOOGLE_ANALYTICS_EVENTS.getLabel({
                              item: GOOGLE_ANALYTICS_EVENTS.LABEL_CONSTANTS
                                .LOGIN_PAGE.CREATE_AN_ACCOUNT,
                              buttonName: 'Create new account',
                            }),
                          })
                        }
                        className="text-p2 underline"
                      >
                        {t('signinPage.register')}
                      </a>
                    </Link>
                  </span>
                </>
              </div>
            ) : (
              <div
                className={classNames(
                  'text-center text-grayv2-900 lg:text-left',
                  loginState !== LoginState.PASSWORD && 'hidden',
                )}
              >
                <Link
                  href={{
                    pathname: WEB_PATHS.FORGOT_PASSWORD,
                    query: router.query,
                  }}
                >
                  <a className="text-p2 underline">
                    {t('signinPage.forgotPassword')}
                  </a>
                </Link>{' '}
              </div>
            )}
          </div>
          <ButtonV2
            variantClassName={classNames(
              ButtonV2Rounded.large,
              ButtonV2Style.primary,
              lg ? ButtonV2Size.small : ButtonV2Size.medium,
              'w-full lg:w-1/3 h-fit',
            )}
            buttonProps={{
              disabled:
                !formik.isValid ||
                loginState === LoginState.SSO_LOGIN ||
                loginState === LoginState.ACTIVATE_ACCOUNT ||
                !formik.dirty,
            }}
          >
            {t('next')}
          </ButtonV2>
        </div>
      </form>
    </FormikProvider>
  );
};

const ErrorContainer: React.FunctionComponent = ({ children }) => {
  return (
    <div className="flex rounded-md bg-redv2-50 px-2 py-4 text-left text-p1 font-semibold text-redv2-700">
      <span className=" mr-2 text-xl">
        <HiOutlineXCircle />
      </span>
      {children}
    </div>
  );
};

const MessageContainer: React.FunctionComponent = ({ children }) => {
  return (
    <div className="text-black-600 rounded bg-green-400 p-4 text-caption font-semibold opacity-70">
      {children}
    </div>
  );
};

const QueryErrors: React.FunctionComponent = () => {
  const { query, ERROR_CODE } = useLmsRouteRedirectQuery();

  return (
    <>
      {[
        ERROR_CODE.ERROR_OAUTH,
        ERROR_CODE.ERROR_SAML_LOGIN,
        ERROR_CODE.ERROR_SAML_SSO,
      ].includes(query.code) && (
        <ErrorContainer>{query.message}</ErrorContainer>
      )}
      {Object.keys(ERROR_CODES_MAP).includes(query.code) && (
        <ErrorContainer>{ERROR_CODES_MAP[query.code]}</ErrorContainer>
      )}
    </>
  );
};

enum LoginState {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  SSO_LOGIN = 'SSO_LOGIN',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
}

export default function LoginV2() {
  const { t } = useTranslation();
  const [form, setForm] = useState<ILoginForm>({ email: '', password: '' });
  const [error, setError] = useState('');
  const [isEmailNotFound, setIsEmailNotFound] = useState(false);
  const [loginState, setLoginState] = useState(LoginState.EMAIL);
  const router = useRouter();

  const errorCode = router.query.error as string;

  const onSubmit = async (
    payload: ILoginForm,
    formikHelpers: FormikHelpers<ILoginForm>,
  ) => {
    if (loginState === LoginState.EMAIL) {
      handleValidateEmail(payload, formikHelpers);
    } else {
      handleLoginClick(payload, formikHelpers);
    }
  };

  /**
   * First step, validate email and get info about this account. If it's pass, showing password input and disable email input.
   * @param payload Form Values
   */
  const handleValidateEmail = async (
    payload: ILoginForm,
    formikHelpers: FormikHelpers<ILoginForm>,
  ) => {
    try {
      setIsEmailNotFound(false);
      setError('');

      const response = await authHttp.post(API_PATHS.VALIDATE, {
        usernameOrEmail: payload.email,
      });

      setForm({
        ...form,
        email: payload.email,
      });

      const passwordProvider = response.data?.find(
        (d) => d.provider === 'password',
      );
      const ssoProvider = response.data?.find((d) => d.provider === 'samlSSO');
      const notActivatedInstancyUser = response.data?.find(
        (d) => d.isInstancyUser && !d.hasAuth,
      );

      if (passwordProvider && ssoProvider) {
        setLoginState(LoginState.SSO_LOGIN);
        formikHelpers.setFieldError(
          'email',
          t('signinPage.errors.useTeamSignIn'),
        );
      } else if (passwordProvider && notActivatedInstancyUser) {
        setLoginState(LoginState.ACTIVATE_ACCOUNT);
      } else if (passwordProvider) {
        setLoginState(LoginState.PASSWORD);
        formikHelpers.resetForm({
          values: {
            email: payload.email,
            password: '',
          },
        });
        formikHelpers.setValues({ email: payload.email, password: '' });
      } else if (ssoProvider) {
        setLoginState(LoginState.SSO_LOGIN);
        formikHelpers.setFieldError(
          'email',
          t('signinPage.errors.useTeamSignIn'),
        );
      } else {
        setLoginState(LoginState.SSO_LOGIN);
        formikHelpers.setFieldError(
          'email',
          t('signinPage.errors.useTeamSignIn'),
        );
      }
    } catch (error) {
      const err = error as AxiosError;
      console.error(error);
      if (err.response && err.response.data.statusCode === 404) {
        setIsEmailNotFound(true);
        formikHelpers.setFieldError(
          'email',
          t('signinPage.errors.emailNotExists'),
        );
      } else if (
        err.response &&
        err.response.data.code === ERROR_CODES.ERROR_USER_DEACTIVATED.code
      ) {
        setError(t('signinPage.errors.userIsDeactivated'));
      }
    }
  };

  /**
   * Second step, send email and password to login on server and get access token.
   * @param payload Form Values
   */
  const handleLoginClick = async (
    payload: ILoginForm,
    formikHelpers: FormikHelpers<ILoginForm>,
  ) => {
    setForm({
      ...form,
      password: payload.password,
    });
    formikHelpers.resetForm({
      values: { ...form, password: payload.password },
    });
    await login({ email: form.email, password: payload.password });
  };

  /**
   * Method that send email & password to verify on auth service.
   * @param payload Form Values
   * @returns
   */
  const login = async (payload: ILoginForm = {}) => {
    try {
      setError('');
      const recaptcha = await getReCaptchaToken('login');
      const response = await axios.post(NEXT_API_PATHS.LOGIN, {
        ...payload,
        recaptcha,
      });
      const { accessToken, accessTokenExpiry, user } = response.data;

      if ([SAML_LOGIN_ROUTE].includes(router.pathname) && router.query.TARGET) {
        handleSamlSPSSORedirection(accessToken, router.query.TARGET as string);
        return;
      } else if (router.query.referer) {
        authLogin(
          {
            jwtToken: accessToken,
            jwtTokenExpiry: accessTokenExpiry,
            user,
          },
          false,
          router.query.referer as string,
        );
        return;
      }
      authLogin({
        jwtToken: accessToken,
        jwtTokenExpiry: accessTokenExpiry,
        user,
      });
    } catch (error) {
      if (
        error?.response?.data?.data?.code ===
        ERROR_CODES.INCORRECT_EMAIL_OR_PASSWORD.code
      ) {
        setError(t('signinPage.errors.incorrectEmailOrPassword'));
      } else if (error?.response?.data) {
        setError(error.response.data.data?.message);
      } else {
        setError(ERRORS.GENERIC_NETWORK_ERROR);
      }
    }
  };

  const handleGoBack = () => {
    setError(undefined);
    setLoginState(LoginState.EMAIL);
  };

  const head = useMemo(
    () => (
      <Head>
        <title>
          {t('headerText')} | {t('login')}
        </title>
      </Head>
    ),
    [],
  );

  useEffect(() => {
    if (!errorCode) return;

    const foundError = ERROR_CODES[errorCode];
    if (foundError.code === ERROR_CODES.ERROR_USER_DEACTIVATED.code) {
      setError(t('signinPage.errors.userIsDeactivated'));
    } else {
      setError(foundError.message);
    }
  }, [errorCode]);

  return (
    <LoginLayout head={head} showFooter>
      <div className="rounded-3xl bg-white p-8">
        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_SITE_KEY}`}
        ></Script>
        <div className="mb-[30px] flex justify-center">
          <div className="p-3.5">
            <div className="relative h-[54px] w-[54px]">
              <Image
                src="/assets/yournextu-logo-vertical-compact-v2.png"
                layout="fill"
                objectFit="contain"
              />
            </div>
          </div>
        </div>
        <div className="mb-[30px] flex flex-col justify-center gap-y-2 text-left text-subheading font-bold lg:gap-y-4 lg:text-h6">
          {(loginState === LoginState.EMAIL ||
            loginState === LoginState.SSO_LOGIN) && (
            <>
              <span>{t('login')}</span>
              <span className="text-p1 font-regular text-grayv2-500 lg:text-p3">
                {t('signinPage.signinDescription')}
              </span>
            </>
          )}
          {loginState === LoginState.PASSWORD && <span>{t('password')}</span>}
        </div>
        {loginState === LoginState.ACTIVATE_ACCOUNT && (
          <MessageContainer>
            {t('signinPage.activationMessage')}
          </MessageContainer>
        )}
        <LoginForm
          email={form.email}
          password={form.password}
          loginState={loginState}
          onSubmit={onSubmit}
          isEmailNotFound={isEmailNotFound}
          handleGoBack={handleGoBack}
          error={error}
        />
      </div>
    </LoginLayout>
  );
}
